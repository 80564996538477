import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const KtOzellikler = () => {
    return (
        <div className="features-area pt-80 pb-50 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Özellikler</h2>
                    <div className="bar"></div>
                    <p>Ambalaj firmalarının ve grafiklerin ihtiyacı olan kutu bıçak izine sadece en boy yükseklik bilgisi girerek ulaşabilirsiniz.İndirdiğiniz vektörel çizimlere dilediğiniz zaman ulaşıp revize edebilirsiniz </p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Sadece En Boy Yükseklik Girerek
                                </Link>
                            </h3>
                            <p>Yüzlerce modeli sadece en boy yükseklik bilgisi girerek doğru ve güvenilir bir şekilde indirip kullanabilirsiniz.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                   3D Mockup
                                </Link>
                            </h3>
                            <p>Arge çalışmaları devam eden 3d Mockup hizmetimiz çok yakında devreye alınacaktır. Böylece ambalajınızı sadece 1 dakikada 3 boyutlu izleyebileceksiniz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Grid />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                   Yüzlerce Model 
                                </Link>
                            </h3>
                            <p>En çok kullanılan modellerin dışında ihtiyacınız olan model çalışmaları sürekli devam etmektedir</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Info />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                 Hassas Çizimler - Maket Çalışmaları
                                </Link>
                            </h3>
                            <p>Çizimlerdeki hassasiyeti sizler için analiz edip sektöründe lider ambalaj kalıp firması ile maket test çalışmaları yapıyoruz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Box />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Montaj
                                </Link>
                            </h3>
                            <p>Hızlı montaj ve açılım hesaplama, maliyet analizleri yapabilirsiniz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Bell />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Teklif Süreçleri
                                </Link>
                            </h3>
                            <p>Arge çalışmaları devam eden teklif süreçleri yönetimini çok yakında buradan yapabileceksiniz</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Arşiv
                                </Link>
                            </h3>
                            <p>İndirmiş olduğunuz çizimlere güvenli bir şekilde 7/24 online ulaşabilirsiniz </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                   Mobil Uygulama
                                </Link>
                            </h3>
                            <p>Mobil uygulamamızdan ve Web sitemizden bir tık kadar yakınınızdayız. Açılım öğrenmek artık çok kolay</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KtOzellikler;  